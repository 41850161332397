
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import type { ElForm } from "element-plus";

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  setup() {
    onMounted(() => {
      if (!route.params.id) {
        setCurrentPageBreadcrumbs("目录新增", ["目录管理;box_categories"])
      } else {
        setCurrentPageBreadcrumbs("目录编辑", ["目录管理;box_categories", route.params.id.toString()])
      }
    });

    const rules = ref({
      // name: [
      //   {
      //     required: true,
      //     message: "商圈名称不可为空",
      //     trigger: "change",
      //   },
      // ],
    });

    const formRef = ref<FormInstance>();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const box_category = ref({} as any)

    const onCancel = () => {
      router.push({ name: "box_categories" });
    };

    const isNew = !route.params.id;

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return;

      formEl.validate((valid) => {
        if (valid) {
          if (isNew) {
            store
              .dispatch(
                Actions.CREATE_BOX_CATEGORY,
                box_category.value
              )
              .then(() => {
                router.push({ name: "box_categories" });
              })
              .catch(() => {
                // const [error] = Object.keys(store.getters.getErrors);
                // Swal.fire({
                //   text: store.getters.getErrors[error],
                //   icon: "error",
                //   buttonsStyling: false,
                //   confirmButtonText: "Try again!",
                //   customClass: {
                //     confirmButton: "btn fw-bold btn-light-danger",
                //   },
                // });
              });
          } else {
            store
              .dispatch(Actions.UPDATE_BOX_CATEGORY, {
                id: route.params.id,
                values: box_category.value,
              })
              .then(() => {
                router.push({ name: "box_categories" });
              })
              .catch(() => {
                // const [error] = Object.keys(store.getters.getErrors);
                // Swal.fire({
                //   text: store.getters.getErrors[error],
                //   icon: "error",
                //   buttonsStyling: false,
                //   confirmButtonText: "Try again!",
                //   customClass: {
                //     confirmButton: "btn fw-bold btn-light-danger",
                //   },
                // });
              });
          }
        } else {
          return false;
        }
      });
    };

    if (!isNew) {
      store
        .dispatch(Actions.GET_BOX_CATEGORY, route.params.id)
        .then(() => {
          box_category.value = store.getters.currentBoxCategory

          imageUrl.value = box_category.value.image_url.startsWith("http")
            ? box_category.value.image_url
            : ""
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    const imageUrl = ref("")

    const onChange = (file) => {
      var reader = new FileReader();
      reader.readAsDataURL(file.raw)
      reader.onload = (e) => {
        imageUrl.value = e.target?.result?.toString() || ""
        box_category.value.image = imageUrl.value
      }
    }

    return {
      onSubmit,
      box_category,
      rules,
      onCancel,
      formRef,
      onChange,
      imageUrl
    };
  },
});
