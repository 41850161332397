
import { defineComponent } from "vue";
import BoxCategoryForm from "./Form.vue";

export default defineComponent({
  components: {
    BoxCategoryForm,
  },
  setup() {
    return {};
  },
});
